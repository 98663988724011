import React from "react";
import tw, { styled } from "twin.macro";
import _ from "lodash";
import moment from "moment-timezone";
import { getContrastText, hasPassed } from "../utils";
import { TZ } from "../constants";
import CalendarGameItem from "./CalendarGameItem";
import CalendarEventItem from "./CalendarEventItem";

const CalendarContainer = styled.div`
  ${tw`w-full overflow-x-auto`}
`;
const GridContainerWrapper = styled.div`
  ${tw`min-w-[884px] py-8 mx-auto`}
`;

const GridHeader = styled.div`
  ${tw`w-full grid grid-cols-7`}
`;
const HeaderItem = styled.div`
  ${tw`text-center px-2.5 py-1.5 text-amber-900 text-lg font-medium min-w-[120px]`}
`;

const GridContainer = styled.div`
  ${tw`w-full grid grid-cols-7 gap-[1px] bg-gray-200 border-[1px] border-gray-200`}
`;

const GridItem = styled.div`
  ${tw`
  relative flex flex-col items-center min-w-[120px] min-h-[120px] bg-white text-xs 
  `}
`;

const DateContainer = styled.div`
  ${tw`absolute left-1 top-1 text-sm`}
  color: ${(props) => props.color};
`;

const FootNote = styled.div`
  ${tw`flex w-[884px] mx-auto`}
`;

const Legend = styled.div`
  ${tw`flex mx-auto mb-4`}
`;

const LegendLabel = styled.div`
  ${tw`flex mx-2 items-center`}
`;

const LegendColorBlock = styled.div`
  ${tw`w-[72px] h-12 border border-gray-300`}
  background-color: ${(props) => props.backgroundColor};
  ${({ striped, backgroundColor, promoColor }) =>
    striped &&
    `background: repeating-linear-gradient(135deg, ${promoColor},${promoColor} 10px,${backgroundColor} 10px,${backgroundColor} 20px);`}
`;
const LegendText = styled.div`
  ${tw`text-sm ml-1`}
`;
const LegendSvgContainer = styled.div`
  ${tw`w-[72px] h-12 bg-gray-100  border border-transparent flex justify-center items-center`}
`;
const LegendSvg = styled.img`
  ${tw`w-8 h-8 mx-1`}
`;

const Calendar = ({
  currentDate,
  games,
  events,
  teamsByName,
  homeGameColor,
  awayGameColor,
  promoGameColor,
  timezone,
  site,
  homeTeams = [],
}) => {
  const showMiniVersion = site === "academy" && homeTeams.length === 7;
  const currentMonth = moment.tz(currentDate, timezone).month();
  const gamesForCurrentMonth = _.filter(
    games,
    (game) =>
      moment.tz(game.node.frontmatter.date, timezone).month() === currentMonth
  );
  const gamesByDate = _.groupBy(gamesForCurrentMonth, (game) =>
    moment.tz(game.node.frontmatter.date, timezone).format("YYYY-MM-DD")
  );
  const eventsForCurrentMonth = _.filter(events, (event) => {
    const { startDate, endDate } = event.node.frontmatter;
    const momentStartDate = moment.tz(startDate, timezone);
    const momentEndDate = moment.tz(endDate, timezone);
    return (
      currentMonth >= momentStartDate.month() &&
      currentMonth <= momentEndDate.month()
    );
  });
  const eventsByDate = getGroupedEvents(eventsForCurrentMonth, currentDate);
  const dates = createDates(currentDate, gamesByDate, eventsByDate, timezone);

  return (
    <CalendarContainer>
      <GridContainerWrapper>
        <GridHeader>
          <HeaderItem>Sun</HeaderItem>
          <HeaderItem>Mon</HeaderItem>
          <HeaderItem>Tue</HeaderItem>
          <HeaderItem>Wed</HeaderItem>
          <HeaderItem>Thu</HeaderItem>
          <HeaderItem>Fri</HeaderItem>
          <HeaderItem>Sat</HeaderItem>
        </GridHeader>
        <GridContainer>
          {dates.map((d, i) => {
            const displayDate = moment.tz(d.date, timezone).date();
            const month = moment.tz(d.date, timezone).month();
            const isCurrentMonth = month === currentMonth;
            const firstGame = _.get(d, "games.0");
            let color = "#000000";
            if (firstGame) {
              const firstHomeGame = homeTeams.includes(
                _.toLower(firstGame.node.frontmatter.homeTeam)
              );

              color = firstHomeGame
                ? getContrastText(homeGameColor)
                : getContrastText(awayGameColor);

              if (firstGame.node.frontmatter.bgColor) {
                color = getContrastText(firstGame.node.frontmatter.bgColor);
              }
            }
            const firstEvent = _.get(d, "events.0");
            if (firstEvent) {
              color = getContrastText(
                firstEvent.node.frontmatter.highlightColor
              );
            }

            let counterDict = {};
            return (
              <GridItem key={i}>
                {isCurrentMonth && (
                  <>
                    {d.events.map((event, idx) => {
                      return <CalendarEventItem event={event} />;
                    })}
                    {d.games.map((game, idx) => {
                      const {
                        promoGame,
                        homeTeam,
                        awayTeam,
                        date,
                        bgColor,
                        yr,
                      } = game.node.frontmatter;

                      const isHomeGame = homeTeams.includes(
                        _.toLower(homeTeam)
                      );
                      const opponentTeamName = isHomeGame ? awayTeam : homeTeam;
                      const opponentTeam = teamsByName[opponentTeamName];
                      const homeTeamDoc = teamsByName[homeTeam];
                      const awayTeamDoc = teamsByName[awayTeam];

                      const momentDate = moment.tz(
                        game.node.frontmatter.date,
                        timezone
                      );
                      const formattedTime = momentDate.format("h:mm A z");

                      const teamsKey = `${awayTeam}|${homeTeam}`;
                      const sameTeamsHasMultiple =
                        _.get(d, ["gamesByTeams", teamsKey], []).length > 1;

                      const isCompletedGame = hasPassed(momentDate);

                      if (sameTeamsHasMultiple) {
                        if (teamsKey in counterDict) {
                          counterDict[teamsKey]++;
                        } else {
                          counterDict[teamsKey] = 1;
                        }
                      }

                      const slug = `/schedule/${_.kebabCase(yr)}/${_.kebabCase(
                        `${awayTeam}-${homeTeam}`
                      )}-${moment(date).tz(TZ).format("YYYY-MM-DD")}${
                        sameTeamsHasMultiple ? `-${counterDict[teamsKey]}` : ""
                      }`;

                      let backgroundColor = isHomeGame
                        ? homeGameColor
                        : awayGameColor;

                      if (bgColor) {
                        backgroundColor = bgColor;
                      }
                      return (
                        <CalendarGameItem
                          key={`${i}-${idx}`}
                          backgroundColor={backgroundColor}
                          promoColor={promoGameColor}
                          striped={promoGame && !bgColor}
                          slug={slug}
                          isHomeGame={isHomeGame}
                          isCompletedGame={isCompletedGame}
                          opponentTeam={opponentTeam}
                          formattedTime={formattedTime}
                          game={game}
                          color={color}
                          displayDate={displayDate}
                          homeTeamDoc={homeTeamDoc}
                          awayTeamDoc={awayTeamDoc}
                          site={site}
                          showMiniVersion={showMiniVersion}
                        />
                      );
                    })}
                    <DateContainer color={color}>{displayDate}</DateContainer>
                  </>
                )}
              </GridItem>
            );
          })}
        </GridContainer>
      </GridContainerWrapper>
      <FootNote>
        <Legend>
          <LegendLabel>
            <LegendColorBlock backgroundColor={homeGameColor} />
            <LegendText> - Home</LegendText>
          </LegendLabel>
          <LegendLabel>
            <LegendColorBlock backgroundColor={awayGameColor} />
            <LegendText> - Away</LegendText>
          </LegendLabel>
          {/* <LegendLabel>
            <LegendColorBlock backgroundColor="#FFFFFF" />
            <LegendText> - Completed</LegendText>
          </LegendLabel> */}
          {/* <LegendLabel>
            <ActionSvg src="/img/svg/ticket.svg" alt="buy_ticket_link" />
            <LegendText> - Buy Tickets</LegendText>
          </LegendLabel> */}
          {site === "dawgs" && (
            <LegendLabel>
              {/* <LegendColorBlock
              backgroundColor={homeGameColor}
              promoColor={promoGameColor}
              striped={true}
            /> */}
              <LegendSvgContainer>
                <LegendSvg src="/img/svg/asterisk.svg" alt="promo_game" />
              </LegendSvgContainer>
              <LegendText> - Promotion</LegendText>
            </LegendLabel>
          )}
        </Legend>
      </FootNote>
    </CalendarContainer>
  );
};

export default Calendar;

const createDates = (currentDate, gamesByDate, eventsByDate, timezone) => {
  const currentMomentDate = moment.tz(currentDate, timezone);
  let startingDate = moment
    .tz(timezone)
    .year(currentMomentDate.year())
    .month(currentMomentDate.month())
    .date(1);

  // number of rows to show all days in the month
  let rows = 5;
  const daysInMonth = startingDate.daysInMonth();
  const firstDayOftheMonth = startingDate.day();
  if (firstDayOftheMonth + daysInMonth > 35) {
    rows = 6;
  }
  // default calendar week start on Sundays
  startingDate = startingDate.date(startingDate.date() - startingDate.day());

  let dates = [];
  for (let i = 0; i < rows * 7; i++) {
    const date = startingDate.toISOString();
    const dateKey = startingDate.format("YYYY-MM-DD");
    const dateGames = gamesByDate[dateKey] || [];
    const dateEvents = eventsByDate[dateKey] || [];

    const groupedDateGames = _.groupBy(dateGames, ({ node }) => {
      const { homeTeam, awayTeam } = _.get(node, "frontmatter", {});
      return `${awayTeam}|${homeTeam}`;
    });

    dates = [
      ...dates,
      {
        date,
        games: dateGames,
        events: dateEvents,
        gamesByTeams: groupedDateGames,
      },
    ];
    startingDate.date(startingDate.date() + 1);
  }

  return dates;
};

const getGroupedEvents = (events, currentDate) => {
  let eventsByDate = {};
  let startingDate = moment.tz(currentDate, TZ).startOf("month");
  const daysInMonth = startingDate.daysInMonth();

  for (let i = 1; i < daysInMonth + 1; i++) {
    const dateKey = startingDate.format("YYYY-MM-DD");
    events.forEach((event) => {
      const { startDate, endDate } = event.node.frontmatter;
      const momentStartDate = moment.tz(startDate, TZ).startOf("day");
      const momentEndDate = moment.tz(endDate, TZ).endOf("day");
      if (
        startingDate.isSameOrAfter(momentStartDate) &&
        startingDate.isSameOrBefore(momentEndDate)
      ) {
        eventsByDate[dateKey] = [...(eventsByDate[dateKey] || []), event];
      }
    });

    startingDate.date(startingDate.date() + 1);
  }
  return eventsByDate;
};
